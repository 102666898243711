@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.mukta-medium {
  font-family: "Mukta", sans-serif;
  font-weight: 500;
  font-style: normal;
}


a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  /* Removes bullet points */
  padding: 0;
  /* Removes default padding */
}

body {
  font-family: Mukta, sans-serif;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vw;
}


.LeftNav {
  display: flex;
  justify-content: start;
  font-size: 1.25vw;
  margin: 0;
  padding: 0;
  color: #355E3B;
}

.LeftNav li {
  margin-left: 2vw;
  text-decoration: none;
}

.RightNav {
  display: flex;
  justify-content: end;
  margin: 0;
  padding: 0;
}

.RightNav li {
  margin-right: 2vw;
  text-decoration: none;
}

.RightNav button {
  width: 5vw;
  background-color: #355E3B;
  border-radius: .6vw;
  border-style: none;
  cursor: pointer;
}

#header_button {
  display: flex;
  justify-content: center;  
  align-items: center;       
  width: 10vw;
  height: 2.5vw;
  background-color: #355E3B;
  border-radius: .6vw;
  border-style: none;
  color: white;
  font-size: 1em;
  margin-top: 1vw;
  text-align: center;
  cursor: pointer;
  text-decoration: none; 
}
/* #header_button:hover{
  background-color: blue;
} */
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
}

#head1,
#head2 {
  margin: 0;
  font-size: 2vw;
}


.images {
  display: flex;
  flex-direction: column;
}

#PPOS {
  width: 30vw;
  border-radius: 1vw;
  margin-left: auto;
  margin-right: 10vw;
  margin-bottom: 2vw;
}

#PPOS_label {
  width: 30vw;
  border-radius: 1vw;
  margin-left: auto;
  margin-right: 10vw;
}


.aboutMe {
  background-color: darkgrey;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 2rem;
  margin-bottom: 0;
  width: 100vw;
  position: absolute;
  bottom: 0;
  /* margin-top: -5vw; */
}

#aboutMeTitle {
  font: 2em sans-serif;
  margin-right: 2rem;
}

#aboutMeTXT {
  width: 50vw;
  font-size: 1em;
  text-align: left;
}


#ph1 {
  margin: 0;
  margin-top: 1vw;
  margin-bottom: .5vw;
  padding: 0;
  font-size: 2.5rem;
  margin-left: 5vw;   
}

#hr1 {
  width: 90vw;             
  border: none;            
  border-top: 2px solid lightgray; 
  margin: 0;
  margin-left: 5vw;       
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 5vw; 
  margin-top: -5vw;
}

.left-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%; 
}
.right-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%; 
}

.vertical-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2vw; 
}

#hr2 {
  border: none;
  border-left: 2px solid lightgray; 
  height: 50vh; 
  margin: 0; 
}

.project_list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project_card {
  width: 30vw;
  height: 6vw;
  border: 2px solid black;
  margin-bottom: 2vw;
  text-align: center;
  border-radius: 1vw;
  position: relative;
}

.project_img {
  width: 7vw;
  position: absolute;
  left: 1vw;
  top: 50%;
  transform: translateY(-50%);
}

.card_title {
  font-size: 1.5rem;
  /* margin-left: 4vw; */
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 2vw; */
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}
.project_counter{
  border: 2px solid beige;
  border-radius: 1vw;
  width: 4vw;
  text-align: center;
  position: absolute;
  margin-left: 15vw;
  margin-top: -.25vw;
}
.project_page_img{
  width: 25vw;
}
.project_page_desc{
  width: 25vw;
}
@media (max-width: 600px) {
  .center-container {
    flex-direction: column;
    padding: 0;
  }

  .left-section,
  .vertical-divider,
  .right-section {
    width: 100%; 
    margin-bottom: 2vw; 
  }

  .project_card {
    width: 80vw;
    height: auto;
  }

  .project_img {
    width: 20vw;
  }

  .card_title {
    margin-left: 25vw;
  }

  #hr2 {
    height: 20vw; 
    border-left-width: 1px; 
  }
}
